import React, { FC, useEffect, useMemo, useState } from 'react';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { navigate } from '@reach/router';
import classNames from 'classnames';
import { STRING } from 'shared/constants/strings';

import Icon from 'common/Icon';
import Button, { BUTTON } from 'components/Button';
import { isBrowser } from 'utils/browser';
import { getPathForQuery } from 'utils/getPathForQuery';

import { PaginationProps } from './models';

import './Pagination.scss';

const Pagination: FC<PaginationProps> = ({
  total,
  itemsPerPage,
  currentPage,
  setCurrentPage,
  customClass,
}) => {
  const [currentPaginationPage, setCurrentPaginationPage] = useState<number>(currentPage);
  const pageCount: number = useMemo(() => Math.ceil(total / itemsPerPage), [total, itemsPerPage]);

  const location: string | undefined = isBrowser() ? window.location.search : undefined;
  const queryString: URLSearchParams = useMemo(() => new URLSearchParams(location), [location]);

  useEffect(() => {
    const urlPageQuery: string | null = queryString.get('page');
    if (urlPageQuery) {
      setCurrentPaginationPage(Number(urlPageQuery));
    }
  }, [queryString]);

  const isPaginationPrevDisabled = currentPaginationPage === 1;
  const isPaginationNextDisabled = currentPaginationPage === pageCount;

  const pages = useMemo(() => {
    const paginationNumber = Array.from(new Array(pageCount || 0), (_, k) => k + 1);

    if (pageCount < 5 || currentPaginationPage < 5) {
      return [...paginationNumber.slice(0, 5)];
    }

    if (pageCount - currentPaginationPage < 3) {
      return [...paginationNumber.slice(-5)];
    }

    if (currentPaginationPage === pageCount) {
      return [...paginationNumber.slice(-5)];
    }

    return paginationNumber.slice(currentPaginationPage - 3, currentPaginationPage + 2);
  }, [currentPaginationPage, pageCount]);

  const handlePaginationClick = (number: number) => {
    setCurrentPage(number);
    navigate(`${getPathForQuery()}?${STRING.PAGE}=${number}`, { replace: true });
  };

  const handlePaginationPrev = (number: number) => {
    if (isPaginationPrevDisabled) return;
    const prevNumber = number === 1 ? 1 : number - 1;
    navigate(`${getPathForQuery()}?${STRING.PAGE}=${prevNumber}`, { replace: true });
    setCurrentPage(prevNumber);
  };

  const handlePaginationNext = (number: number, maxPage: number) => {
    if (isPaginationNextDisabled) return;
    const nextNumber = number === maxPage ? maxPage : number + 1;
    navigate(`${getPathForQuery()}?${STRING.PAGE}=${nextNumber}`);
    setCurrentPage(nextNumber);
  };

  const paginationClassess = classNames('pagination', customClass);

  const paginationButtonClassess = (number: number): string =>
    classNames({
      'button--pagination--active': currentPaginationPage === number,
    });

  const paginationButtons = pages.map(
    (number: number, index: number): JSX.Element => (
      <Button
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        variant={BUTTON.VARIANT.PAGINATION}
        onClick={() => handlePaginationClick(number)}
        customClass={paginationButtonClassess(number)}
      >
        {number}
      </Button>
    )
  );

  const prevButton = (
    <Button
      variant={BUTTON.VARIANT.PAGINATION}
      ariaLabel={STRING.PREVIOUS}
      onClick={() => handlePaginationPrev(currentPaginationPage)}
      disabled={isPaginationPrevDisabled}
    >
      <Icon icon={faChevronLeft} />
    </Button>
  );

  const nextButton = (
    <Button
      variant={BUTTON.VARIANT.PAGINATION}
      ariaLabel={STRING.NEXT}
      onClick={() => handlePaginationNext(currentPaginationPage, pageCount)}
      disabled={isPaginationNextDisabled}
    >
      <Icon icon={faChevronRight} />
    </Button>
  );

  const showPagination =
    pageCount > 1 ? (
      <section className={paginationClassess}>
        {prevButton}
        {paginationButtons}
        {nextButton}
      </section>
    ) : null;

  return showPagination;
};

export default Pagination;
