import React, { FC, useState } from 'react';
import { useScreenService } from 'hooks/useScreenService';
import { NUMBER } from 'shared/constants/numbers';

import { Container } from 'layout';
import ArticleCard from 'components/ArticleCard';
import PageIntro from 'components/PageIntro';
import Pagination from 'components/Pagination';

import { ArticleListingProps } from './models';

import './ArticleListing.scss';

const ArticleListing: FC<ArticleListingProps> = ({ introductionText, articleCards }) => {
  const [currentPage, setCurrentPage] = useState<number>(NUMBER.FIRST_PAGE);

  const { isMobile } = useScreenService();

  const articlesPerPage = isMobile
    ? NUMBER.ARTICLES_PER_MOBILE_PAGE
    : NUMBER.ARTICLES_PER_DESKTOP_PAGE;

  if (!articleCards) return null;

  const articleList =
    currentPage === NUMBER.FIRST_PAGE
      ? articleCards?.slice(0, articlesPerPage)
      : articleCards?.slice(articlesPerPage * (currentPage - 1), articlesPerPage * currentPage);
  if (currentPage !== NUMBER.FIRST_PAGE && !articleList.length) {
    setCurrentPage(NUMBER.FIRST_PAGE);
  }

  const renderArticles = articleList?.map(
    ({ educationImage, title, introduction, button }: CommonTypes.ArticleCardType) => (
      <li key={title}>
        <ArticleCard
          image={educationImage}
          title={title}
          description={introduction}
          button={button}
        />
      </li>
    )
  );

  return (
    <Container fluid noPadding element="section" className="article-listing">
      <PageIntro customClass="article-listing__intro" text={introductionText} />
      <Container className="article-listing__wrapper">
        <ul className="article-listing__list">{renderArticles}</ul>
        <Pagination
          total={articleCards?.length}
          itemsPerPage={articlesPerPage}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          customClass="article-listing__pagination"
        />
      </Container>
    </Container>
  );
};

export default ArticleListing;
