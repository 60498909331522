import React, { FC } from 'react';
import classNames from 'classnames';

import { Container } from 'layout';
import Typography from 'common/Typography';

import { PageIntroProps } from './models';

import './PageIntro.scss';

const PageIntro: FC<PageIntroProps> = ({ text, customClass }) => {
  const pageIntroTextClassnames = classNames('page-intro__text', customClass);

  return (
    <Container noPadding fluid className="page-intro">
      <Container>
        <Typography variant="paragraph3" align="left" customClass={pageIntroTextClassnames}>
          {text}
        </Typography>
      </Container>
    </Container>
  );
};

export default PageIntro;
