import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';

import { BannerContentWrapperProps } from './models';

import './BannerContentWrapper.scss';

const BannerContentWrapper: FC<BannerContentWrapperProps> = ({
  title,
  description,
  button,
  customClass,
  purpose,
  headingVariant,
  descriptionVariant,
}) => {
  const renderButton = () => {
    if (!button) return null;

    const {
      ariaLabel,
      buttonText,
      buttonLink: { url },
    } = button;

    return (
      <Button
        variant={BUTTON.VARIANT.CONTAINED}
        color={BUTTON.COLOR.PRIMARY}
        ariaLabel={ariaLabel}
        link={url}
        icon
        customClass="banner-content__button"
      >
        {buttonText}
      </Button>
    );
  };

  const bannerContentClass = classNames({
    [`banner-content ${customClass}`]: customClass,
    [`banner-content--${purpose}`]: purpose,
  });

  return (
    <div className={bannerContentClass}>
      <Typography variant={headingVariant} weight="medium" customClass="banner-content__heading">
        {title}
      </Typography>
      <Typography
        variant={descriptionVariant}
        customClass="banner-content__description"
        dangerouslySetInnerHTML={description}
      />
      {renderButton()}
    </div>
  );
};

export default BannerContentWrapper;
