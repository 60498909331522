import React, { FC } from 'react';
import classNames from 'classnames';

import Image from 'common/Image';
import BannerContentWrapper from 'components/BannerContentWrapper';
import { PURPOSE } from 'components/BannerContentWrapper/models.d';

import { BANNER, BannerProps } from './models.d';

import './Banner.scss';

const Banner: FC<BannerProps> = ({ banner: { title, description, image }, variant }) => {
  const bannerClass = classNames('banner', { [`banner--${variant}`]: variant });

  return (
    <div className={bannerClass}>
      {variant === BANNER.VARIANT.PRIMARY ? (
        <Image imageData={image} alt={image.altText} className="banner__image" />
      ) : null}
      <BannerContentWrapper
        title={title}
        description={description}
        customClass="banner__content"
        purpose={PURPOSE.PAGE_BANNER}
        headingVariant="heading1"
        descriptionVariant="heading5"
      />
      {variant === BANNER.VARIANT.SECONDARY ? (
        <Image imageData={image} alt={image.altText} className="banner__image" />
      ) : null}
    </div>
  );
};
export default Banner;
