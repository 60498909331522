import { TypographyVariant } from 'common/Typography/models.d';

export interface BannerContentWrapperProps {
  title: string;
  description: string;
  button?: CommonTypes.ButtonType;
  customClass?: string;
  purpose: PURPOSE;
  headingVariant: TypographyVariant;
  descriptionVariant: TypographyVariant;
}

export enum PURPOSE {
  HERO_BANNER = 'hero-banner',
  PAGE_BANNER = 'page-banner',
}
