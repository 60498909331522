export interface BannerProps {
  banner: CommonTypes.PageBannerType;
  variant: BANNER.VARIANT;
}

export namespace BANNER {
  export enum VARIANT {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
  }
}
