import React, { FC } from 'react';
import { Link } from '@reach/router';
import { useScreenService } from 'hooks/useScreenService';

import Image from 'common/Image';
import Typography from 'common/Typography';
import Button, { BUTTON } from 'components/Button';

import { ArticleCardProps } from './models';

import './ArticleCard.scss';

const ArticleCard: FC<ArticleCardProps> = ({ image, title, description, button }) => {
  const { isMobile } = useScreenService();

  const {
    buttonText,
    buttonLink: { url },
    ariaLabel,
  } = button;

  const renderButton = () => {
    if (!button) return null;

    return (
      <Button
        variant={BUTTON.VARIANT.LINK}
        color={BUTTON.COLOR.PRIMARY}
        customClass="article-card__button"
        icon
        ariaLabel={ariaLabel}
        link={url}
      >
        {buttonText}
      </Button>
    );
  };

  return (
    <article className="article-card" data-testid="article-card">
      <Link to={url}>
        <Image imageData={image} alt={image.altText} className="article-card__image" />
        <Typography
          variant={isMobile ? 'heading6' : 'heading4'}
          weight="bold"
          customClass="article-card__title"
        >
          {title}
        </Typography>
      </Link>
      <Typography
        variant="paragraph1"
        weight="regular"
        customClass="article-card__description"
        dangerouslySetInnerHTML={description}
      />
      {renderButton()}
    </article>
  );
};

export default ArticleCard;
